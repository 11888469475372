import React from "react";
import {
  SEO,
  MarkdownContent,
  FadeReveal,
  FullImageCard,
  FishermanIcon,
} from "@bluefin/components";
import { Grid, Segment, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class FindYourStylistPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.sahairsalon.com/find-your-stylist/"}
        />
        <Grid
          className={"custom-page-find-your-stylist custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"wwkkbiunpvenwedo"}
            style={{ padding: 16 }}
            textAlign={"center"}
            columns={1}
          >
            <Grid.Column
              className={"pdkjfccywzqwnqyu"}
              style={{ padding: 16 }}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "6c628108-354d-4c84-a154-533f5c7900a6",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"yewrjofffjzdzkil"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"xnmgtbsegikjvgmn"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__0348af47-7f57-4d65-87e6-fc39fc1e714a"}
                >
                  <div className={"home-page"}>
                    <Grid
                      stackable={true}
                      className={
                        "component-section-container location-container services-section"
                      }
                      verticalAlign={"middle"}
                      textAlign={"center"}
                    >
                      <Grid.Column width={16} textAlign={"center"}>
                        <Card.Group
                          className={"services"}
                          itemsPerRow={2}
                          stackable={true}
                        >
                          <FadeReveal
                            cascade={true}
                            cascadeFactor={0.4}
                            triggerOnce={true}
                            className={"ui card"}
                            disableAnimationOnMobile={true}
                            noContainer={true}
                          >
                            <FullImageCard
                              link={true}
                              image={
                                "https://fisherman.gumlet.io/public/2286dd05-ce7e-4212-aad4-b899cc74c6f0/6Z4A2566.jpg"
                              }
                              as={"a"}
                              href={"https://app.joinmya.com/sahairmainstreet"}
                              target={"_blank"}
                              rel={"noopener noreferrer"}
                            >
                              <div>
                                <Card.Header
                                  content={"Get Matched In"}
                                  as={"h6"}
                                />
                                <Card.Header content={"Parker"} />
                              </div>
                              <FishermanIcon iconName={"arrow-right"} />
                            </FullImageCard>
                            <FullImageCard
                              link={true}
                              image={
                                "https://fisherman.gumlet.io/public/2286dd05-ce7e-4212-aad4-b899cc74c6f0/6Z4A2696.jpg"
                              }
                              as={"a"}
                              href={"https://app.joinmya.com/sahairlincoln"}
                              target={"_blank"}
                              rel={"noopener noreferrer"}
                            >
                              <div>
                                <Card.Header
                                  content={"Get Matched In"}
                                  as={"h6"}
                                />
                                <Card.Header content={"Lonetree"} />
                              </div>
                              <FishermanIcon iconName={"arrow-right"} />
                            </FullImageCard>
                          </FadeReveal>
                        </Card.Group>
                      </Grid.Column>
                    </Grid>
                  </div>
                </React.Fragment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 29682 }) {
      title
      seoTitle
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
